.mission_vision_sec {
  background: url("../../images/bgimages/missionvisionbg1.png"),
    url("../../images/bgimages/missionvisionbg2.png"), rgb(0, 0, 0);
  background-position: top right, bottom left;
  background-repeat: no-repeat;
  .tag_line,
  .main_title {
    color: $white;
  }
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  .mission_vision_item {
    margin-top: 2rem;
    border: 1px solid $lightwhite;
    padding: 2rem 1rem;
    position: relative;
    // height: 100%;
    max-height: 100px;
    transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      width: 0;
      background-color: $secondarycolor;
      transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
    }
    h3 {
      font-family: "Roboto", serif;
      color: $white;
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 500;
    }
    p {
      color: $lightwhite;
      font-size: 18px;
      font-weight: 400;
      margin: 2rem 0 0 0;
      text-align: justify;
      opacity: 0;
      transform: translateY(10px);
      transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
      @media #{$sm} {
        font-size: 16px;
      }
    }
    .icon_wrape {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon_style {
        color: $secondarycolor;
        font-size: 80px;
        transform: translateY(-70px);
        transition: 0.6s;
        opacity: 0;
      }
    }
    &:hover {
      max-height: 100%;
      &::before {
        width: 100%;
      }
      p {
        opacity: 1;
        transform: translateY(0);
      }
      .icon_wrape {
        .icon_style {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
