// explore our blog css
.explore_our_blog_sec {
  background: url("../../images/bgimages/moreinfobg.png");
  background-position: top right;
  background-repeat: no-repeat;
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  .explore_our_blog_item {
    figure {
      height: 300px;
      overflow: hidden;
      cursor: pointer;
      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        .image {
          transform: scale(1.2);
        }
      }
    }

    .explore_our_blog_item_detail {
      ul {
        margin-bottom: 0.5rem;
        li {
          color: $black;
          font-size: 14px;
          font-weight: 400;
          text-transform: capitalize;
          span {
            color: $secondarycolor;
          }
        }
      }
      h3 {
        color: $black;
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 500;
      }
      p {
        color: $lightblack;
        font-size: 18px;
        font-weight: 400;
        margin: 0.5rem 0 0 0;
        @media #{$sm} {
          font-size: 16px;
        }
      }
    }
  }
}

// blog list css
.our_blog_list_sec {
  background: url("../../images/bgimages/aboutbg.png");
  background-position: top left;
  background-repeat: no-repeat;
  .main_title {
    text-align: center;
  }

  .categories_wrape {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    li {
      color: $black;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      cursor: pointer;
      padding: 0.5rem 0.7rem;
      //   box-shadow: 0 5px 24px rgba(6, 40, 61, 0.1);
      border-radius: 20px;
      margin-bottom: 0.6rem;
      &:hover,
      &.active {
        color: $white;
        background: $secondarycolor;
      }
    }
  }
  .row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2rem 0;
  }
  figure {
    height: 200px;
    overflow: hidden;
    margin: 0;
    cursor: pointer;
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .image {
        transform: scale(1.2);
      }
    }
  }

  .our_blog_list_item {
    ul {
      margin-bottom: 0.5rem;
      li {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        span {
          color: $secondarycolor;
        }
      }
    }
    h3 {
      color: $black;
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 500;
    }
    p {
      color: $lightblack;
      font-size: 18px;
      font-weight: 400;
      margin: 0.5rem 0 0 0;
      @media #{$sm} {
        font-size: 16px;
      }
    }
  }
}
