.faqs_sec {
  background: url("../images/bgimages/faqbg.png");
  background-position: top left;
  background-repeat: no-repeat;
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  figure {
    height: 300px;
    overflow: hidden;
    .image {
      // object-fit: cover;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .accordion-item {
    border-bottom: 1px solid $secondarycolor;
    .accordion-button {
      padding: 1rem 0;
      font-family: "Roboto", serif;
      font-size: 18px;
      font-weight: 500;
      &:focus {
        box-shadow: none;
      }
      &:not(.collapsed) {
        background-color: $white;
        border-bottom: 1px solid $secondarycolor;
        color: $black;
        box-shadow: none;
      }
    }
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
      p {
        color: $lightblack;
        font-size: 16px;
        text-align: justify;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
