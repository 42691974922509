// blog details sec
.blog_inner_details_sec {
  background: url("../../images/bgimages/morebg1.png"),
    url("../../images/bgimages/morebg2.png");
  background-position: top left, right center;
  background-repeat: no-repeat;
  .main_title {
    text-align: center;
    margin-bottom: 2rem;
  }
  figure {
    height: 600px;
    overflow: hidden;
    margin: 0;
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    @media #{$sm} {
      height: 400px;
    }
  }
  .blog_details_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 1rem 0;
    li {
      color: $black;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
      span {
        color: $secondarycolor;
      }
    }
  }
  h3 {
    color: $black;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
  }
  p {
    color: $lightblack;
    font-size: 18px;
    font-weight: 400;
    margin: 1.5rem 0;
    text-align: justify;
    @media #{$sm} {
      font-size: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    margin: 1.5rem 0;
    li {
      color: $lightblack;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0.5rem;
      @media #{$sm} {
        font-size: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// similar blogs css
.similar_blogs_sec {
  background: url("../../images/bgimages/aboutbg.png");
  background-position: top left;
  background-repeat: no-repeat;
  .header_wrape {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$sm} {
      display: block;
    }
    .arrows_wrape {
      @media #{$sm} {
        text-align: end;
      }
      .slider_custom_arrows {
        color: $black;
        border: 1px solid $black;
        border-radius: 50px;
        padding: 0.46rem 0.5rem;
        margin: 0;
        background: transparent;
        &:hover {
          color: $white;
          background: $secondarycolor;
          border: 1px solid $secondarycolor;
        }
        &:disabled {
          color: $lightblack;
          border: 1px solid $lightblack;
        }
      }
    }
  }
  .similar_blog_item {
    padding: 1rem;
    figure {
      height: 300px;
      overflow: hidden;
      cursor: pointer;
      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        .image {
          transform: scale(1.2);
        }
      }
    }

    .similar_blog_detail {
      ul {
        margin-bottom: 0.5rem;
        li {
          color: $black;
          font-size: 14px;
          font-weight: 400;
          text-transform: capitalize;
          span {
            color: $secondarycolor;
          }
        }
      }
      h3 {
        color: $black;
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 500;
      }
      p {
        color: $lightblack;
        font-size: 18px;
        font-weight: 400;
        margin: 0.5rem 0 0 0;
        @media #{$sm} {
          font-size: 16px;
        }
      }
    }
  }
}
