// portfolio details Part Css

.portfolio_inner_detail_sec {
  background: url("../../images/bgimages/portfoliodetailbg.png");
  background-position: top right;
  background-repeat: no-repeat;
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  p {
    color: $lightblack;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: justify;
    @media #{$sm} {
      font-size: 16px;
    }
    &:last-child {
      margin: 0;
    }
  }
  ul {
    li {
      color: $lightblack;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0.5rem;
      @media #{$sm} {
        font-size: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  figure {
    height: 300px;
    overflow: hidden;
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .detail_item {
    background: $black;
    padding: 1.5rem;
    ul {
      margin: 0;
      li {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem 0;
        border-bottom: 1px solid $secondarycolor;
        text-transform: uppercase;
        margin: 0;
        &:first-child {
          padding: 0 0 0.7rem 0;
        }
        &:last-child {
          border-bottom: none;
          padding: 0.7rem 0 0 0;
        }
        span {
          font-weight: 400;
          text-transform: capitalize;
          .socialIcons {
            display: flex;
            align-items: center;
            margin: 0;
            li {
              padding: 0;
              display: flex;
              align-items: center;
              a {
                margin-right: 0.7rem;
                color: $white;
                -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  -webkit-transform: translateY(-5px);
                  transform: translateY(-5px);
                  -webkit-transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                  transition: 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                  color: $white;
                }
                .icon-style {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// portfolio gallery css

.portfolio_gallery_sec {
  background: url("../../images/bgimages/portfoliogallerybg.png"), rgb(0, 0, 0);
  background-position: top left;
  background-repeat: no-repeat;
  .tag_line,
  .main_title {
    color: $white;
  }
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  figure {
    height: auto;
    overflow: hidden;
    cursor: pointer;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

//whychoose sec css

.why_choose_us_sec {
  background: url("../../images/bgimages/whychoosebg.png");
  background-position: top right;
  background-repeat: no-repeat;
  .line_style {
    width: 25%;
    @media #{$sm} {
      width: 50%;
    }
  }
  .arrows_wrape {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    @media #{$sm} {
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 0 2rem 0;
    }
    .slider_custom_arrows {
      color: $black;
      border: 1px solid $black;
      border-radius: 50px;
      padding: 0.46rem 0.5rem;
      margin: 0;
      background: transparent;
      position: relative;
      z-index: 2;
      &:hover {
        color: $white;
        background: $secondarycolor;
        border: 1px solid $secondarycolor;
      }
      &:disabled {
        color: $lightblack;
        border: 1px solid $lightblack;
      }
    }
  }

  .slick-dots {
    top: -62px;
    bottom: unset;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    z-index: -1;
    li {
      z-index: 1;
      &.slick-active {
        button {
          &::before {
            border: 1px solid $black;
            border-radius: 50px;
            transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
            -webkit-transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 630%;
        top: 43%;
        left: 100%;
        right: 67px;
        height: 2px;
        background: $black;
        z-index: -1;
        @media #{$md} {
          width: 410%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        width: 630%;
        top: 43%;
        right: 100%;
        height: 2px;
        background: $black;
        z-index: -1;
        @media #{$md} {
          width: 410%;
        }
      }
    }
  }

  figure {
    height: 300px;
    overflow: hidden;
    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-family: "Roboto", serif;
    font-size: 60px;
    font-weight: 600;
    text-transform: capitalize;
    color: transparent;
    -webkit-text-stroke: 1px $secondarycolor;
    text-stroke: 1px $secondarycolor;
  }
  h5 {
    color: $black;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
  }
  p {
    color: $lightblack;
    font-size: 18px;
    font-weight: 400;
    margin: 1rem 0;
    text-align: justify;
    @media #{$sm} {
      font-size: 16px;
    }
  }
}
